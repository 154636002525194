export const columns = [
    {
        title: '商户名称',
        dataIndex: 'name',
        key: 'name',
    },
    { title: '所属项目', dataIndex: 'projectName', key: 'projectName', },
    { title: '创建人', dataIndex: 'createName', key: 'createName' },
    { title: '创建时间', dataIndex: 'createAt', key: 'createAt', },
    {
        title: '最后编辑人',
        dataIndex: 'updateName',
        key: 'updateName',
    },
    {
        title: '最后编辑时间',
        dataIndex: 'updateAt',
        key: 'updateAt',
    },
    {
        title: '操作',
        fixed: 'right',
        key: 'action',
        width: '150px',
        scopedSlots: { customRender: 'action' },
    },
];