<template>
    <div>
        <ds-header title="商户管理"></ds-header>
        <div>
            <a-button type="primary" @click="visible = true; placeId = ''; title = '新增商户'"> 新增商户 </a-button>
        </div>
        <a-table @change="pageChange" :columns="columns" style="margin-top: 10px"
            :pagination="pagination.total ? pagination : false" :data-source="tableData" :rowKey="(record) => record.id"
            bordered :scroll="{ x: 3200 }">
            <span slot="project_name_list" slot-scope="text, record" class="action">
                <a-tooltip>
                    <template slot="title">
                        {{ record.project_name_list }}
                    </template>
                    <span class="field">{{ record.project_name_list }}</span>
                </a-tooltip>
            </span>
            <span slot="action" slot-scope="text, record" class="action">
                <!-- <a-button type="link" @click="update(record)" size="small"> 编辑 </a-button> -->
                <a-button type="link" style="color: #ff4d4f;" @click="deleteStaff(record)" size="small"> 删除 </a-button>
            </span>
        </a-table>
        <!-- 新增弹窗 -->
        <a-modal :maskClosable="false" :title="title" :visible="visible" :confirm-loading="confirmLoading" @ok="handleOk"
            @cancel="handleCancel">
            <a-form-model ref="ruleForm" :model="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" :rules="rules">
                <a-form-model-item label="商户名称" prop="name">
                    <a-input v-model.trim="form.name" placeholder="请输入商户名称，内容不超过20个字" :maxLength="20" />
                </a-form-model-item>
                <a-form-model-item label="所属项目" prop="projectId">
                    <a-select v-model="form.projectId" placeholder="请选择所属项目">
                        <a-select-option v-for="item in projectMsgList" :key="item.projectId" :value="item.projectId">
                            {{ item.projectName }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>
<script>
import * as  api from '@/api/demandPool';
import { columns } from './index';
export default {
    data() {
        return {
            visible: false,
            confirmLoading: false,
            columns,
            tableData: [],
            title: '',
            pagination: {
                showSizeChanger: true,
                current: 1,
                pageSize: 10,
                total: 0,
                showQuickJumper: true,
                showTotal: (total) => {
                    return `共 ${total} 条`;
                },
            },
            form: {
                projectId: undefined,
                name: ''
            },
            placeId: '',
            projectMsgList: [],
            rules: {
                name: [{ required: true, message: '请输入商户名称', trigger: 'blur' }],
                projectId: [{ required: true, message: '请选择所属项目', trigger: 'blur' }],
            },
        }
    },
    created() {
        this.getTableList()
        this.getProject()
    },
    methods: {
        handleCancel() {
            Object.assign(this.form, {
                projectId: '',
                name: ''
            })
            this.$refs.ruleForm.resetFields();
            this.visible = false;
        },
        pageChange(option) {
            this.pagination.current = option.current;
            this.pagination.pageSize = option.pageSize;
            this.getTableList();
        },
        async handleOk() {
            let res = ''
            console.log(this.placeId);
            if (this.placeId) {
                res = await api.tenantUpdate({ ...this.form, commercialTenantId: this.placeId })
            } else {
                res = await api.tenantAdd(this.form);
            }

            if (res.code === '200') {
                this.$message.success(res.msg);
                this.handleCancel()
                this.getTableList();
            } else {
                this.$message.error(res.msg);
            }
        },
        getTableList() {
            api.tenantList({ size: this.pagination.pageSize, page: this.pagination.current, name: '' }).then(res => {
                console.log(res);
                this.tableData = res.data.list
                this.pagination.total = res.data.total
            })
        },
        update(record) {
            this.placeId = record.commercialTenantId;
            this.form.name = record.name;
            this.form.projectId = record.projectId;
            this.visible = true;
            this.title = '编辑商户'
        },
        // 删除地点
        deleteStaff(item) {
            const self = this;
            this.$confirm({
                title: '确认删除商户',
                content: '删除后此数据将无法恢复, 确定要删除吗',
                onOk() {
                    self.placeDeleted(item.commercialTenantId);
                },
                onCancel() { },
            });
        },
        async placeDeleted(placeId) {
            let res = await api.tenantDeleted(placeId)
            if (res.code === '200') {
                this.$message.success(res.msg);
                this.handleCancel()
                this.getTableList();
            } else {
                this.$message.error(res.msg);
            }
        },
        //获取项目信息
        async getProject() {
            const res = await api.requirementProject2();
            if (res.code == 200) {
                this.projectMsgList = res.data;
                this.projectMsgList.push({
                    projectId: "社区用户",
                    projectName: "社区用户"
                })
            }
        },
    }
}
</script>